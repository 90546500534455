import { getReviewIDFromURL } from './getReviewIDFromURL';
import APIReviewsDataService from '../service/API/APIReviewsDataService';
import { ErrorExceptions } from '../types/Models';
import { aquaClinics } from '../data/aqua-clinics';
import envConfig, { WidgetOrigin } from '../constants';

const [reviewID] = getReviewIDFromURL();

export const handlerReviewStatus = async (setErrorCode: Function, setScreenLoader: Function) => {
  if (!reviewID) throw new Error('Review ID is not found');

  const reviewInfo = await APIReviewsDataService.getReviewInfo(reviewID, setErrorCode);
  const clinicId = reviewInfo.getReviewRequest()?.getClinicId();
  const bookingHasReview = reviewInfo.getReviewRequest()?.getHasReview();
  const { WIDGET_ORIGIN } = envConfig;
  const isAquaWidget = WIDGET_ORIGIN === WidgetOrigin.aquadental;
  const isDevMode = envConfig.NODE_ENV === 'development';

  if (!clinicId) {
    setTimeout(() => {
      setErrorCode(ErrorExceptions.SUBMISSION_FAILED);
    }, 500);
    return { reviewInfo: reviewInfo.toObject(), clinicInfo: null };
  }

  const aquasClinicIds = aquaClinics.map((city) => city.clinics.map((clinic) => clinic.id)).flat();

  if (!isDevMode && isAquaWidget) {
    if (!aquasClinicIds.includes(clinicId)) {
      setTimeout(() => {
        setErrorCode(ErrorExceptions.SUBMISSION_FAILED);
        throw new Error('Clinic ID is not found in Aqua Clinics');
      }, 500);
      return { reviewInfo: reviewInfo.toObject(), clinicInfo: null };
    }
  }

  if (bookingHasReview) {
    setTimeout(() => {
      setErrorCode(ErrorExceptions.ALREADY_COMPLETED);
    }, 500);
    return { reviewInfo: reviewInfo.toObject(), clinicInfo: null };
  }

  const clinicInfo = await APIReviewsDataService.getReviewClinicInfo(clinicId, setErrorCode).finally(
    () => {
      setTimeout(() => {
        setScreenLoader(false);
      }, 500);
    },
  );

  return { reviewInfo: reviewInfo.toObject(), clinicInfo: clinicInfo.toObject() };
};
