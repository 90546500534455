import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import {
  Route, Switch, useHistory, useLocation,
} from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useFeature } from '@growthbook/growthbook-react';
import { CreateReviewForClinicResponse, GetClinicResponse } from 'patient-api-lib';
import { useTranslation } from 'react-i18next';
import { useErrorHandling } from './hooks/useErrorHandling';
import { getConfig, ConfigContext } from './config';
import { FlowContainer } from './components/FlowContainer/FlowContainer';
import { getUrlChapterNumber, getUrlScreenNumber } from './utils/getUrlData';
import { getPercentageIncrease } from './utils/getPercentageIncrease';
import {
  AnswerData, AppConfig, ConfigFeatures, GrowthBookFeatures, SubmitActionTypes,
} from './types/Models';
import { QuestionScreen } from './screens/QuestionScreen';
import { ConfirmationScreen } from './screens/ConfirmationScreen';
import { checkScreenSubmittable } from './utils/checkScreenSubmittable';
import { SelectorScreen } from './screens/SelectorScreen';
import { calculateScreenLength } from './utils/filteringSteps';
import { Wrapper } from './components/Wrapper/Wrapper';
import APIReviewsDataService from './service/API/APIReviewsDataService';
import { getWidgetConfig } from './helpers/getWidgetConfig';
import { getReviewIDFromURL } from './helpers/getReviewIDFromURL';
import { handlerReviewStatus } from './helpers/handlerReviewStatus';
import I18NService from './service/I18NService';

const App: React.FC = function () {
  const CONFIRM_MODAL_ON = useFeature<GrowthBookFeatures>(GrowthBookFeatures.CONFIRM_REVIEW).value || false;
  const GOOGLE_REVIEW_BUTTON = useFeature<GrowthBookFeatures>(GrowthBookFeatures.GOOGLE_REVIEW_BUTTON).value || false;
  const TRUSTPILOT_REVIEW_BUTTON = useFeature<GrowthBookFeatures>(GrowthBookFeatures.TRUSTPILOT_REVIEW_BUTTON).value || false;
  const configFeatures: Partial<ConfigFeatures> = useMemo(
    () => ({
      CONFIRM_MODAL_ON,
      GOOGLE_REVIEW_BUTTON,
      TRUSTPILOT_REVIEW_BUTTON,
    }),
    [CONFIRM_MODAL_ON, GOOGLE_REVIEW_BUTTON, TRUSTPILOT_REVIEW_BUTTON],
  );
  const { screenLoader: defaultScreenLoader } = useContext(ConfigContext);
  const history = useHistory();
  const location = useLocation();
  const screenNumber = getUrlScreenNumber(location.pathname);
  const chapterNumber = getUrlChapterNumber(location.pathname);
  const [currentScreen, setCurrentScreen] = useState<number>(screenNumber);
  const [currentChapter, setCurrentChapter] = useState<number>(chapterNumber);
  const { t } = useTranslation();
  const config = useMemo(() => getConfig(t, configFeatures, currentChapter), [t, configFeatures, currentChapter]);
  const [appConfig, setAppConfig] = useState<AppConfig>({ ...config });
  const { chapters } = appConfig;
  const [showOverviewOnMobile, setShowOverviewOnMobile] = useState<boolean>(true);
  const [screenLoader, setScreenLoader] = useState(defaultScreenLoader);
  const [loading, setLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(config.progress);
  const screensLength = calculateScreenLength(chapters, chapterNumber);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<string>('');
  const [errorObject, setErrorObject] = useErrorHandling(errorCode);
  const [widgetConfig] = getWidgetConfig();
  const [reviewID, queryParams] = getReviewIDFromURL();
  const [clinicInfo, setClinicInfo] = useState<GetClinicResponse.AsObject | null>(null);
  const [submitResponse, setSubmitResponse] = useState<CreateReviewForClinicResponse | null>(null);

  useEffect(() => {
    const handleConfigUpdate = () => {
      setAppConfig((state) => ({ ...state, ...getConfig(t, configFeatures, currentChapter, submitResponse, clinicInfo) }));
    };
    I18NService.onLanguageChanged(handleConfigUpdate);

    handleConfigUpdate();

    return () => {
      I18NService.offLanguageChanged(handleConfigUpdate);
    };
  }, [t, submitResponse, currentChapter, clinicInfo, configFeatures]);

  const getReviewInfo = useCallback(async () => {
    const response = await handlerReviewStatus(setErrorCode, setScreenLoader);

    if (response?.clinicInfo) {
      setClinicInfo(response.clinicInfo);
    }
  }, []);

  useEffect(() => {
    if (!reviewID) {
      history.push('/chapter0/screen0');
      return;
    }

    getReviewInfo();

    history.push(`/chapter0/screen0${queryParams ? `?${queryParams}` : ''}`);
  }, [reviewID]);

  useEffect(() => {
    const updateProgress = () => {
      const allScreens: { [key: string]: boolean } = {};
      chapters.forEach((chapter, chapterIndex) => chapter.screens.forEach((_, screenIndex: number) => {
        allScreens[`chapter-${chapterIndex}/screen-${screenIndex}`] = true;
      }));

      const calculateProgress = () => getPercentageIncrease(screensLength, screenNumber + 1);

      return setProgress(calculateProgress());
    };

    updateProgress();
  }, [currentChapter, chapters, currentScreen, location.pathname, location, screensLength, screenNumber]);

  const updateScreenData = (data: AnswerData[], chapterIndex: number, screenIndex: number) => {
    const updatedConfig = { ...appConfig };

    updatedConfig.chapters[chapterIndex].screens[screenIndex].data = { answers: [...data] };
    setAppConfig({
      ...updatedConfig,
    });
  };

  const runSubmitAction = async (action: SubmitActionTypes) => {
    const clinicId = appConfig.chapters[currentChapter].screens.find((item) => item.id === 'SELECTOR_CLINIC');
    const chooseClinicId = clinicId?.data?.answers[0]?.inputValue || widgetConfig?.companyId;

    switch (action) {
      case 'SEND_REVIEW_FOR_CLINIC':
        return APIReviewsDataService.sendReviewsByClinic(chapters, setErrorCode, chooseClinicId);

      case 'SEND_REVIEW_FOR_BOOKING':
        if (reviewID) {
          return APIReviewsDataService.sendReviewsByBooking(chapters, setErrorCode, reviewID);
        }
        return new Promise((_, reject) => {
          setTimeout(reject, 3000);
        });

      default:
        break;
    }
  };

  const navigateToNextScreen = async (submitAction: SubmitActionTypes, chapterIndex: number, screenIndex: number) => {
    if (submitAction) {
      setLoading(true);
      const response = await runSubmitAction(submitAction);
      setSubmitResponse(response as CreateReviewForClinicResponse);
      setLoading(false);
    }

    if (chapters[chapterIndex].screens.length - 1 > screenIndex) {
      setCurrentScreen(screenIndex + 1);
      return history.push(`/chapter${chapterIndex}/screen${screenIndex + 1}`);
    }

    if (chapters[chapterIndex + 1] && chapters[chapterIndex + 1].screens) {
      setCurrentScreen(0);
      setCurrentChapter(chapterIndex + 1);
      return history.push(`/chapter${chapterIndex + 1}/screen0`);
    }

    setCurrentChapter(chapterIndex + 1);
  };

  const navigateToPreviouslyScreen = async (chapterIndex: number, screenIndex: number) => {
    if (chapters[chapterIndex].screens.length - 1 > screenIndex) {
      setCurrentScreen(screenIndex - 1);
      return history.push(`/chapter${chapterIndex}/screen${screenIndex - 1}`);
    }
  };

  return (
    <ConfigContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        ...appConfig,
        submitResponse,
        showConfirmModal,
        progress,
        currentScreen,
        currentChapter,
        errorObject,
        errorCode,
        screensLength,
        screenLoader,
        updateScreenData,
        setCurrentChapter,
        setCurrentScreen,
        setProgress,
        setErrorCode,
        setShowConfirmModal,
        setSubmitResponse,
        setErrorObject,
        setScreenLoader,
      }}
    >
      <Wrapper>
        <FlowContainer showOverviewOnMobile={showOverviewOnMobile} onStepClick={() => setShowOverviewOnMobile(false)}>
          <TransitionGroup>
            <CSSTransition key={location.key} classNames="fade" timeout={300} unmountOnExit>
              <Switch location={location}>
                {chapters.map((chapter, chapterIndex) => chapter.screens.map((screen, screenIndex) => (
                  <Route key={`chapter${chapterIndex}/screen${screenIndex}`} path={`/chapter${chapterIndex}/screen${screenIndex}`} exact>
                    {(() => {
                      switch (screen.type) {
                        case 'question':
                          return (
                            <QuestionScreen
                              screenConfig={{
                                progress,
                                ...screen,
                                submittable: checkScreenSubmittable(screen),
                                isLoading: loading,
                              }}
                              onDataChange={(data: AnswerData[]) => updateScreenData(data, chapterIndex, screenIndex)}
                              onSubmit={(submitAction: SubmitActionTypes) => navigateToNextScreen(submitAction, chapterIndex, screenIndex)}
                              onBackClick={() => navigateToPreviouslyScreen(chapterIndex, screenIndex)}
                              screenIndex={screenNumber}
                              screensLength={screensLength}
                            />
                          );

                        case 'selector':
                          return (
                            <SelectorScreen
                              screenConfig={{
                                progress,
                                ...screen,
                                submittable: checkScreenSubmittable(screen),
                                isLoading: loading,
                              }}
                              onDataChange={(data: AnswerData[]) => updateScreenData(data, chapterIndex, screenIndex)}
                              onSubmit={(submitAction: SubmitActionTypes) => navigateToNextScreen(submitAction, chapterIndex, screenIndex)}
                              onBackClick={() => navigateToPreviouslyScreen(chapterIndex, screenIndex)}
                              screenIndex={screenNumber}
                              screensLength={screensLength}
                            />
                          );

                        case 'confirmation':
                          return (
                            <ConfirmationScreen
                              {...screen}
                              screenIndex={screenNumber}
                              screensLength={screensLength}
                              progress={progress}
                            />
                          );

                        default:
                          break;
                      }
                    })()}
                  </Route>
                )))}
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        </FlowContainer>
      </Wrapper>
    </ConfigContext.Provider>
  );
};

export default App;
